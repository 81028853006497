<!-- 交付验收单 -->
<template>
  <div class="deliver-receipt">
    <div class="title">提交后，车辆状态将变为“已交付”，请确认车辆满足下述的验收标准：</div>
    <div class="standard-content">
      <ol>
        <li>车辆外观完好(激光雷达、摄像头、超声波雷达、触摸屏、车辆涂装无破损)</li>
        <li>车辆开关机功能正常</li>
        <li>车辆前照灯、转向灯、制动灯等灯光工作正常</li>
        <li>轮胎状态正常</li>
        <li>手机遥控前进、后退、左右转向正常</li>
        <li>车辆可执行自动驾驶任务</li>
        <li>
          <div>已对无人车使用方(客户)进行了下述操作的充分培训:</div>
          <ul>
            <li>无人车换电&充电操作</li>
            <li>手机业务APP相关操作,包括开关机、遥控、发车等</li>
            <li>其他无人车日常操作规范&应急操作</li>
          </ul>
        </li>
        <li>车辆相关配件已交付,例如车衣/防雨布(如有)</li>
        <li>补能设备(如换电柜、充电器等)备用电池(如有,双方协商费用)</li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.deliver-receipt {
  height: 100%;
  background: white;
  padding: 0 24px 10px;
  .title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(82, 83, 110, 1);
  }
  .standard-content {
    height: calc(100% - 48px);
    border-radius: 4px;
    background: rgba(246, 247, 248, 1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    overflow: auto;

    ol {
      li {
        list-style: normal !important;
      }
    }

    ul {
      li {
        margin-left: 10px;
        list-style: disc !important;
      }
    }
  }
}
</style>
